function Node(type, val, vsize, entropy) {
  // DO SOME TYPE CHECKING HERE???
  this.type = type;
  this.val = val;
  this.vsize = vsize;
  this.entropy = entropy;
}


module.exports = Node;
