module.exports = {
  "x":"learn",
  "y":"sight",
  "t":"name",
  "+":"feels",
  "*":"great",
  "/":"not",
  "-":"hang",
  "radians":"crowd",
  "degrees":"sexy",
  "sin":"popped",
  "cos":"bout",
  "tan":"level",
  "asin":"action",
  "acos":"keeps",
  "atan":"watch",
  "abs":"logical",
  "sqrt":"tables",
  "sign":"right",
  "floor":"shouldn",
  "ceil":"getting",
  "fract":"blue",
  "pow":"day",
  "mod":"late",
  "min":"sometimes",
  "max":"soda",
  "step":"fizz",
  "smoothstep":"boy",
  "mix":"say",
  "clamp":"paradise",
  "length":"dance",
  "distance":"high",
  "noise":"spin",
  "fbm":"hear",
  "log":"bass",
  "exp":"doing",
  "dot":"happy",
  "normalize":"leaving",
  "texture2D":"follow",
  "swap":"somethin",
  "rotate":"lets",
  "dup":"top",
  "drop":"show"
}
