module.exports = {
  "x":"tail",
  "y":"belly",
  "t":"day",
  "+":"bring",
  "*":"count",
  "/":"divided",
  "-":"calculating",
  "radians":"jupiter",
  "degrees":"heat",
  "sin":"shaw",
  "cos":"athens",
  "tan":"thinking",
  "asin":"front",
  "acos":"land",
  "atan":"supposing",
  "abs":"greatest",
  "sqrt":"fix",
  "sign":"signal",
  "floor":"room",
  "ceil":"pedestal",
  "fract":"small",
  "pow":"capacity",
  "mod":"rest",
  "min":"low",
  "max":"full",
  "step":"move",
  "smoothstep":"quiet",
  "mix":"bundle",
  "clamp":"punish",
  "length":"long",
  "distance":"away",
  "noise":"growl",
  "fbm":"pebbles",
  "log":"tree",
  "exp":"master",
  "dot":"pebble",
  "normalize":"change",
  "texture2D":"brown",
  "swap":"sell",
  "rotate":"turn",
  "dup":"find",
  "drop":"dropped"
}
