module.exports = {
  "x":"fired",
  "y":"return",
  "t":"ready",
  "+":"speaks",
  "*":"billions",
  "/":"floods",
  "-":"dark",
  "radians":"potential",
  "degrees":"currently",
  "sin":"talking",
  "cos":"disposing",
  "tan":"weeks",
  "asin":"history",
  "acos":"midst",
  "atan":"companies",
  "abs":"busy",
  "sqrt":"tireless",
  "sign":"aggressive",
  "floor":"pretty",
  "ceil":"admissions",
  "fract":"access",
  "pow":"troublesome",
  "mod":"expression",
  "min":"monitoring",
  "max":"president",
  "step":"supply",
  "smoothstep":"veterans",
  "mix":"horrible",
  "clamp":"friendly",
  "length":"recorded",
  "distance":"subvert",
  "noise":"sterilization",
  "fbm":"happy",
  "log":"mention",
  "exp":"anywhere",
  "dot":"resource",
  "normalize":"relationship",
  "texture2D":"market",
  "swap":"tremendous",
  "rotate":"stores",
  "dup":"topics",
  "drop":"head"
}
