module.exports = {
  "x": "have",
  "y": "ever",
  "t": "if",
  "+": "wondered",
  "*": "kinda",
  "/": "tried",
  "-": "wished",
  "radians": "when",
  "degrees": "tail",
  "sin": "apparently",
  "cos": "code",
  "tan": "drive",
  "asin": "life",
  "acos": "into",
  "atan": "turning",
  "abs": "death",
  "sqrt": "cry",
  "sign": "breathe",
  "floor": "danger",
  "ceil": "ocean",
  "fract": "fun",
  "pow": "fragments",
  "mod": "like",
  "min": "brittle",
  "max": "subdue",
  "step": "calm",
  "smoothstep": "joy",
  "mix": "poetry",
  "clamp": "spirit",
  "length": "soul",
  "distance": "money",
  "noise": "could",
  "fbm": "you",
  "swap": "its",
  "rotate": "look",
  "dup": "fame",
  "drop": "glory"
}
