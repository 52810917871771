module.exports = {
  "x":"grinding",
  "y":"maelstrom",
  "t":"mutants",
  "+":"voices",
  "*":"texas",
  "/":"enterprise",
  "-":"feast",
  "radians":"pretty",
  "degrees":"troubled",
  "sin":"summon",
  "cos":"wooly",
  "tan":"announced",
  "asin":"stoned",
  "acos":"dress",
  "atan":"believe",
  "abs":"world",
  "sqrt":"sore",
  "sign":"reward",
  "floor":"first",
  "ceil":"play",
  "fract":"severed",
  "pow":"wings",
  "mod":"stars",
  "min":"injuries",
  "max":"moments",
  "step":"beast",
  "smoothstep":"shake",
  "mix":"beach",
  "clamp":"thigh",
  "length":"scaring",
  "distance":"morning",
  "noise":"name",
  "fbm":"romance",
  "log":"wish",
  "exp":"dream",
  "dot":"friendly",
  "normalize":"pleasure",
  "texture2D":"gave",
  "swap":"cock",
  "rotate":"unannounced",
  "dup":"big",
  "drop":"day"
}
