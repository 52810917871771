module.exports = {
  "x":"crystal",
  "y":"keeps",
  "t":"farce",
  "+":"completely",
  "*":"true",
  "/":"wiggling",
  "-":"impossible",
  "radians":"curved",
  "degrees":"going",
  "sin":"abundance",
  "cos":"considered",
  "tan":"serious",
  "asin":"theory",
  "acos":"variety",
  "atan":"sole",
  "abs":"interdependent",
  "sqrt":"bondage",
  "sign":"barrier",
  "floor":"attention",
  "ceil":"awareness",
  "fract":"death",
  "pow":"skin",
  "mod":"shows",
  "min":"people",
  "max":"putting",
  "step":"sparkling",
  "smoothstep":"unnecessary",
  "mix":"wears",
  "clamp":"fields",
  "length":"cognizance",
  "distance":"biological",
  "noise":"run",
  "fbm":"remains",
  "log":"infinite",
  "exp":"futile",
  "dot":"reside",
  "normalize":"together",
  "texture2D":"multicolored",
  "swap":"tubes",
  "rotate":"quite",
  "dup":"thus",
  "drop":"earth"
}
