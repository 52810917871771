module.exports = {
  "x":"begged",
  "y":"arrived",
  "t":"strutted",
  "+":"hunted",
  "*":"praised",
  "/":"induced",
  "-":"treacherous",
  "radians":"efforts",
  "degrees":"agonies",
  "sin":"young",
  "cos":"afternoon",
  "tan":"remained",
  "asin":"thirst",
  "acos":"greece",
  "atan":"safety",
  "abs":"several",
  "sqrt":"judgment",
  "sign":"calculating",
  "floor":"pronounced",
  "ceil":"spoil",
  "fract":"smoking",
  "pow":"licked",
  "mod":"humph",
  "min":"bade",
  "max":"thorn",
  "step":"moaning",
  "smoothstep":"lives",
  "mix":"consented",
  "clamp":"jays",
  "length":"manger",
  "distance":"avaricious",
  "noise":"branch",
  "fbm":"grumbled",
  "log":"eldest",
  "exp":"small",
  "dot":"members",
  "normalize":"pecked",
  "texture2D":"morning",
  "swap":"infirmities",
  "rotate":"arena",
  "dup":"ass",
  "drop":"cosily"
}
