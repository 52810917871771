module.exports = {
  "x":"hips",
  "y":"deep",
  "t":"day",
  "+":"give",
  "*":"realize",
  "/":"battle",
  "-":"just",
  "radians":"spinning",
  "degrees":"cool",
  "sin":"cha",
  "cos":"mmm",
  "tan":"spin",
  "asin":"right",
  "acos":"space",
  "atan":"wantin",
  "abs":"always",
  "sqrt":"cause",
  "sign":"stop",
  "floor":"ground",
  "ceil":"door",
  "fract":"almost",
  "pow":"control",
  "mod":"end",
  "min":"level",
  "max":"high",
  "step":"move",
  "smoothstep":"slow",
  "mix":"sometimes",
  "clamp":"shut",
  "length":"long",
  "distance":"away",
  "noise":"sounds",
  "fbm":"logical",
  "log":"learn",
  "exp":"god",
  "dot":"blue",
  "normalize":"explain",
  "texture2D":"taste",
  "swap":"grab",
  "rotate":"turn",
  "dup":"find",
  "drop":"slipping"
}
