module.exports = {
  "x":"minds",
  "y":"resident",
  "t":"protestant",
  "+":"crucified",
  "*":"glove",
  "/":"satyr",
  "-":"velvet",
  "radians":"ravaged",
  "degrees":"unannounced",
  "sin":"young",
  "cos":"meet",
  "tan":"found",
  "asin":"program",
  "acos":"stuttering",
  "atan":"wake",
  "abs":"connects",
  "sqrt":"morning",
  "sign":"silvery",
  "floor":"driven",
  "ceil":"breed",
  "fract":"croon",
  "pow":"brow",
  "mod":"oooooh",
  "min":"ode",
  "max":"anoint",
  "step":"stoned",
  "smoothstep":"burned",
  "mix":"hours",
  "clamp":"hundred",
  "length":"become",
  "distance":"tounge",
  "noise":"frozen",
  "fbm":"announce",
  "log":"funeral",
  "exp":"small",
  "dot":"decree",
  "normalize":"perimeter",
  "texture2D":"join",
  "swap":"continued",
  "rotate":"gaol",
  "dup":"gripped",
  "drop":"radiant"
}
