module.exports = {
  "x":"poison",
  "y":"night",
  "t":"bass",
  "+":"dawn",
  "*":"felt",
  "/":"round",
  "-":"women",
  "radians":"partner",
  "degrees":"minute",
  "sin":"fire",
  "cos":"run",
  "tan":"seen",
  "asin":"breathe",
  "acos":"wastin",
  "atan":"told",
  "abs":"boy",
  "sqrt":"riding",
  "sign":"superlative",
  "floor":"overprotected",
  "ceil":"taste",
  "fract":"bop",
  "pow":"jumpin",
  "mod":"cha",
  "min":"vibes",
  "max":"popped",
  "step":"wantin",
  "smoothstep":"calling",
  "mix":"again",
  "clamp":"alone",
  "length":"trust",
  "distance":"flowin",
  "noise":"hold",
  "fbm":"tonight",
  "log":"young",
  "exp":"help",
  "dot":"late",
  "normalize":"blue",
  "texture2D":"drive",
  "swap":"warning",
  "rotate":"woman",
  "dup":"wee",
  "drop":"soul"
}
