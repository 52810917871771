module.exports = {
  "x":"curved",
  "y":"tubes",
  "t":"moment",
  "+":"put",
  "*":"grow",
  "/":"separating",
  "-":"just",
  "radians":"universe",
  "degrees":"snow",
  "sin":"aeons",
  "cos":"pulses",
  "tan":"continuum",
  "asin":"apart",
  "acos":"properties",
  "atan":"boundary",
  "abs":"infinite",
  "sqrt":"destroy",
  "sign":"reads",
  "floor":"breathing",
  "ceil":"wall",
  "fract":"staggering",
  "pow":"energy",
  "mod":"entire",
  "min":"level",
  "max":"total",
  "step":"look",
  "smoothstep":"elegant",
  "mix":"variety",
  "clamp":"rule",
  "length":"long",
  "distance":"separated",
  "noise":"noises",
  "fbm":"diatom",
  "log":"information",
  "exp":"marvelously",
  "dot":"blue",
  "normalize":"develop",
  "texture2D":"skin",
  "swap":"new",
  "rotate":"keep",
  "dup":"find",
  "drop":"drops"
}
