module.exports = {
  "x":"told",
  "y":"disposing",
  "t":"cdc",
  "+":"businessman",
  "*":"congratulatory",
  "/":"ventilator",
  "-":"efforts",
  "radians":"grateful",
  "degrees":"ongoing",
  "sin":"spend",
  "cos":"afternoon",
  "tan":"largest",
  "asin":"supplies",
  "acos":"biden",
  "atan":"equipment",
  "abs":"problems",
  "sqrt":"additions",
  "sign":"producer",
  "floor":"agencies",
  "ceil":"develop",
  "fract":"anthony",
  "pow":"sniffles",
  "mod":"deborah",
  "min":"heartbroken",
  "max":"coronavirus",
  "step":"jerry",
  "smoothstep":"antibody",
  "mix":"yesterday",
  "clamp":"project",
  "length":"become",
  "distance":"update",
  "noise":"medicines",
  "fbm":"leaders",
  "log":"vice",
  "exp":"small",
  "dot":"statement",
  "normalize":"red",
  "texture2D":"headed",
  "swap":"directed",
  "rotate":"whistleblower",
  "dup":"iran",
  "drop":"nih"
}
