module.exports = {
  "x":"water",
  "y":"stick",
  "t":"abused",
  "+":"youth",
  "*":"loose",
  "/":"hold",
  "-":"commiserated",
  "radians":"neck",
  "degrees":"opened",
  "sin":"thinking",
  "cos":"far",
  "tan":"roaming",
  "asin":"beast",
  "acos":"body",
  "atan":"afternoon",
  "abs":"blow",
  "sqrt":"death",
  "sign":"snap",
  "floor":"low",
  "ceil":"dish",
  "fract":"idol",
  "pow":"gold",
  "mod":"wandering",
  "min":"found",
  "max":"happen",
  "step":"buzzing",
  "smoothstep":"granted",
  "mix":"son",
  "clamp":"belly",
  "length":"pitcher",
  "distance":"watched",
  "noise":"bleeding",
  "fbm":"mice",
  "log":"often",
  "exp":"jay",
  "dot":"cat",
  "normalize":"moulting",
  "texture2D":"better",
  "swap":"means",
  "rotate":"patty",
  "dup":"avarice",
  "drop":"remain"
}
