module.exports = {
  "x":"york",
  "y":"air",
  "t":"couple",
  "+":"safe",
  "*":"jesus",
  "/":"decreasing",
  "-":"ahead",
  "radians":"built",
  "degrees":"gulf",
  "sin":"witch",
  "cos":"hydroxychloroquine",
  "tan":"smaller",
  "asin":"even",
  "acos":"true",
  "atan":"farmers",
  "abs":"immunity",
  "sqrt":"situation",
  "sign":"personal",
  "floor":"dollars",
  "ceil":"works",
  "fract":"doctors",
  "pow":"biggest",
  "mod":"happy",
  "min":"date",
  "max":"kept",
  "step":"ventilators",
  "smoothstep":"stiff",
  "mix":"absolutely",
  "clamp":"helping",
  "length":"understand",
  "distance":"know",
  "noise":"somehow",
  "fbm":"validated",
  "log":"corps",
  "exp":"headed",
  "dot":"tools",
  "normalize":"scale",
  "texture2D":"targeted",
  "swap":"prepared",
  "rotate":"shape",
  "dup":"especially",
  "drop":"elective"
}
