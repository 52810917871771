module.exports = {
  "x":"angels",
  "y":"guest",
  "t":"divinity",
  "+":"great",
  "*":"mutants",
  "/":"glory",
  "-":"gave",
  "radians":"entry",
  "degrees":"girls",
  "sin":"unannounced",
  "cos":"gives",
  "tan":"hotel",
  "asin":"player",
  "acos":"call",
  "atan":"haired",
  "abs":"best",
  "sqrt":"meaning",
  "sign":"texas",
  "floor":"join",
  "ceil":"plant",
  "fract":"hungry",
  "pow":"wet",
  "mod":"messenger",
  "min":"hour",
  "max":"two",
  "step":"beat",
  "smoothstep":"radio",
  "mix":"lean",
  "clamp":"dreams",
  "length":"stable",
  "distance":"place",
  "noise":"perceive",
  "fbm":"eternal",
  "log":"broken",
  "exp":"died",
  "dot":"staring",
  "normalize":"used",
  "texture2D":"announce",
  "swap":"taught",
  "rotate":"dawn",
  "dup":"insane",
  "drop":"race",
}
