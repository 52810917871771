module.exports = {
  "x":"pillowed",
  "y":"gigantic",
  "t":"day",
  "+":"create",
  "*":"cells",
  "/":"stir",
  "-":"tell",
  "radians":"divinity",
  "degrees":"cold",
  "sin":"texas",
  "cos":"divine",
  "tan":"something",
  "asin":"side",
  "acos":"south",
  "atan":"meaning",
  "abs":"eternal",
  "sqrt":"dig",
  "sign":"enter",
  "floor":"perimeter",
  "ceil":"walls",
  "fract":"small",
  "pow":"plant",
  "mod":"rest",
  "min":"five",
  "max":"sentence",
  "step":"thing",
  "smoothstep":"silky",
  "mix":"breed",
  "clamp":"stiffen",
  "length":"hour",
  "distance":"away",
  "noise":"scream",
  "fbm":"mandala",
  "log":"please",
  "exp":"ode",
  "dot":"blue",
  "normalize":"change",
  "texture2D":"color",
  "swap":"acquire",
  "rotate":"choose",
  "dup":"certain",
  "drop":"rise"
}
