module.exports = {
  "x":"learn",
  "y":"killing",
  "t":"nowhere",
  "+":"taste",
  "*":"partner",
  "/":"supposed",
  "-":"hell",
  "radians":"told",
  "degrees":"tonight",
  "sin":"baby",
  "cos":"addicted",
  "tan":"scared",
  "asin":"bop",
  "acos":"high",
  "atan":"alright",
  "abs":"stop",
  "sqrt":"yet",
  "sign":"bad",
  "floor":"friendly",
  "ceil":"beat",
  "fract":"away",
  "pow":"excited",
  "mod":"answers",
  "min":"flex",
  "max":"work",
  "step":"else",
  "smoothstep":"better",
  "mix":"losing",
  "clamp":"women",
  "length":"hang",
  "distance":"okay",
  "noise":"follow",
  "fbm":"toes",
  "log":"wasn",
  "exp":"hot",
  "dot":"perspective",
  "normalize":"taking",
  "texture2D":"need",
  "swap":"clever",
  "rotate":"talking",
  "dup":"superlative",
  "drop":"don"
}
