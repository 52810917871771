module.exports = {
  "x":"nightmare",
  "y":"living",
  "t":"rhinoceros",
  "+":"aeons",
  "*":"apparently",
  "/":"experienced",
  "-":"awareness",
  "radians":"immeasurably",
  "degrees":"comings",
  "sin":"grow",
  "cos":"run",
  "tan":"remains",
  "asin":"information",
  "acos":"clutch",
  "atan":"environment",
  "abs":"giraffe",
  "sqrt":"frantic",
  "sign":"ranges",
  "floor":"exist",
  "ceil":"develop",
  "fract":"apprehension",
  "pow":"covered",
  "mod":"astrology",
  "min":"gathering",
  "max":"mysteriously",
  "step":"diaphanous",
  "smoothstep":"believing",
  "mix":"futile",
  "clamp":"brief",
  "length":"presences",
  "distance":"organ",
  "noise":"rests",
  "fbm":"swallow",
  "log":"expression",
  "exp":"scrounge",
  "dot":"saying",
  "normalize":"blue",
  "texture2D":"surge",
  "swap":"cognizance",
  "rotate":"egoism",
  "dup":"ears",
  "drop":"relationships"
}
