module.exports = {
  "x":"gladly",
  "y":"together",
  "t":"envious",
  "+":"took",
  "*":"last",
  "/":"pecked",
  "-":"bitter",
  "radians":"stick",
  "degrees":"wooden",
  "sin":"chief",
  "cos":"equally",
  "tan":"tell",
  "asin":"mortal",
  "acos":"think",
  "atan":"latter",
  "abs":"feathers",
  "sqrt":"low",
  "sign":"thus",
  "floor":"peacocks",
  "ceil":"dipped",
  "fract":"court",
  "pow":"notice",
  "mod":"saying",
  "min":"king",
  "max":"fortunate",
  "step":"rest",
  "smoothstep":"set",
  "mix":"room",
  "clamp":"milk",
  "length":"friendly",
  "distance":"befriend",
  "noise":"hat",
  "fbm":"consider",
  "log":"enrage",
  "exp":"porridge",
  "dot":"divided",
  "normalize":"exposed",
  "texture2D":"hard",
  "swap":"calculating",
  "rotate":"cat",
  "dup":"swim",
  "drop":"tormented"
}
