module.exports = {
  "x":"growing",
  "y":"just",
  "t":"hole",
  "+":"old",
  "*":"galaxies",
  "/":"not",
  "-":"staggering",
  "radians":"wondering",
  "degrees":"odd",
  "sin":"vague",
  "cos":"rhinoceros",
  "tan":"rests",
  "asin":"recognizable",
  "acos":"multicolored",
  "atan":"patterns",
  "abs":"apparently",
  "sqrt":"peacock",
  "sign":"includes",
  "floor":"waves",
  "ceil":"futile",
  "fract":"bags",
  "pow":"personal",
  "mod":"oddity",
  "min":"run",
  "max":"hoping",
  "step":"energy",
  "smoothstep":"ever",
  "mix":"nerves",
  "clamp":"magnificence",
  "length":"tree",
  "distance":"remains",
  "noise":"information",
  "fbm":"interdependent",
  "log":"colossal",
  "exp":"theory",
  "dot":"filmy",
  "normalize":"goings",
  "texture2D":"general",
  "swap":"giraffe",
  "rotate":"individuals",
  "dup":"marvelous",
  "drop":"flowing"
}
