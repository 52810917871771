module.exports = {
  "x":"downward",
  "y":"curve",
  "t":"day",
  "+":"bring",
  "*":"decreasing",
  "/":"gulf",
  "-":"just",
  "radians":"christ",
  "degrees":"warm",
  "sin":"france",
  "cos":"modi",
  "tan":"topic",
  "asin":"side",
  "acos":"north",
  "atan":"rudy",
  "abs":"absolutely",
  "sqrt":"problem",
  "sign":"accept",
  "floor":"building",
  "ceil":"wall",
  "fract":"amount",
  "pow":"energy",
  "mod":"continue",
  "min":"least",
  "max":"total",
  "step":"move",
  "smoothstep":"slow",
  "mix":"lot",
  "clamp":"cut",
  "length":"short",
  "distance":"miles",
  "noise":"sound",
  "fbm":"chart",
  "log":"check",
  "exp":"gentleman",
  "dot":"dakota",
  "normalize":"stabilized",
  "texture2D":"clarity",
  "swap":"sell",
  "rotate":"turn",
  "dup":"find",
  "drop":"increase"
}
